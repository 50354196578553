export default {
    components: {},
    mixins: [],
    props: {},
    data: function () {
        return {};
    },
    computed: {},
    watch: {},
    created: function () {},
    beforeDestroy: function () {},
    mounted: function () {},
    methods: {
        getBankLoanRoute() {
            let routeName = this.isAssigned
                ? "ManageAssignedProjectMortgageBankLoan"
                : "ManageProjectMortgageBankLoan";
            return { name: routeName };
        },
        getBankLoanHistoryRoute() {
            let routeName = this.isAssigned
                ? "ManageAssignedProjectMortgageBankLoanHistory"
                : "ManageProjectMortgageBankLoanHistory";
            return { name: routeName };
        },
        getBankLoanOtherRoute() {
            let routeName = this.isAssigned
                ? "ManageAssignedProjectMortgageOther"
                : "ManageProjectMortgageOther";
            return { name: routeName };
        }
    }
};
